import React from "react";
import theme from "theme";
import { Theme, Box, Text, Link, Section, Strong, Icon, Em, Span, LinkBox, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { FaUserTie, FaBinoculars, FaPeopleCarry, FaGooglePlay, FaApple } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Sziklay Bence
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden">
			<Override slot="quarklycommunityKitMenuOverride1" />
		</Components.GlobalHeaderNavBar>
		<Section
			position="relative"
			lg-padding="25px 0 25px 0"
			z-index="0"
			justify-content="center"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
			padding="0 0 0 0"
			overflow-x="hidden"
			overflow-y="hidden"
			color="#000000"
			opacity="1"
			background="#000000"
			sm-padding="0 0 0 0"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				width="100%"
				height="620px"
				justify-content="center"
				sm-width="100%"
				min-width="auto"
				align-items="center"
				lg-height="520px"
				md-height="420px"
				md-padding="0px 24px 0px 24px"
				border-width="100vh"
				overflow-x="hidden"
				overflow-y="hidden"
			/>
			<Box
				quarkly-title="WideoWrap"
				z-index="0"
				postion="absolute"
				overflow-y="hidden"
				flex="0 1 auto"
				min-width="100px"
				position="absolute"
				overflow-x="hidden"
			>
				<Components.QuarklycommunityKitVideo
					src="https://blog.sziklaybence.com/files/herov3hd.mp4"
					autoPlay
					playsInline
					controls={false}
					loop
					muted
					sm-margin="0 0px 0px -28px"
					overflow-x="hidden"
					overflow-y="hidden"
					display="flex"
					poster="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/herofallback.png?v=2023-12-08T19:02:03.788Z"
				>
					<Override slot="Video Tag" />
				</Components.QuarklycommunityKitVideo>
			</Box>
			<Box
				background-color="rgba(0,0,0,0.5)"
				z-index="1"
				position="absolute"
				quarkly-title="DarkLayer"
				right={0}
				bottom={0}
				top={0}
				left={0}
				background="rgba(0, 0, 0, 0.2)"
				sm-display="block"
				sm-background="rgba(0, 0, 0, 0.21)"
				display="none"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				z-index="4"
				quarkly-title="Content"
				position="absolute"
				text-align="left"
				sm-left="3.56773px"
				sm-right="6.666633333333314px"
				sm-width="349.74466666666666px"
				sm-bottom="auto"
				sm-height="293.97085714285714px"
				sm-top="-17px"
				sm-text-align="left"
				display="block"
				left="88px"
				bottom="auto"
				height="330.1738571428572px"
				right="458.47866666666664px"
				top="14px"
				width="683.5213333333334px"
				margin="50px 0px 0px 50px"
				md-margin="50px 0px 0px 0"
				md-left={0}
				lg-left="88px"
				lg-right="803.8123333333333px"
				lg-width="338.18266666666665px"
				xl-left="88px"
				xl-right="561.1456666666666px"
				xl-width="580.8493333333333px"
			>
				<Text
					font="--headline1"
					sm-text-align="left"
					sm-width="100%"
					lg-font="--headline2"
					color="--brandLight"
					letter-spacing="1.5px"
					padding="15px 15xp 15px 15xp"
					margin="16px 0px 16px 15px"
				>
					Sikeres Kommunikáció
				</Text>
				<Text
					sm-text-align="left"
					sm-width="80%"
					font="--lead"
					color="--brandCta"
					letter-spacing="1.5px"
					padding="15px 15px 15px 15px"
				>
					Láss az emberek viselkedése mögé!
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				z-index="4"
				quarkly-title="Content"
				position="absolute"
				text-align="left"
				sm-right="auto"
				sm-width="336.4326666666667px"
				sm-top="auto"
				sm-bottom="30px"
				sm-height="100px"
				sm-text-align="center"
				top="auto"
				right="76px"
				bottom="70px"
				width="344.27442857142853px"
				height="104.45771428571433px"
				margin="50px 0px 0px 50px"
				left="auto"
				sm-margin="0 0px 0px 0"
				md-bottom="50px"
				md-left="auto"
				md-right="70px"
				md-top="auto"
				xl-bottom="54px"
				xl-left="auto"
				xl-right="57px"
				xl-top="auto"
			>
				<Link
					text-decoration-line="initial"
					color="--brandLight"
					letter-spacing="0.5px"
					transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
					href="https://edu.sziklaybence.com/"
					background="--color-brandCta"
					font="--lead"
					hover-transform="translateY(-4px)"
					padding="15px 15px 15px 15px"
					margin="15px 15px 15px 15px"
					display="grid"
					justify-items="end"
					width="fit-content"
					justify-content="flex-end"
					className="ctabutton"
				>
					Beruházok a Fejlődésembe
				</Link>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="0 0 40px 0" background="#000000">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="46%" lg-width="100%">
					<Box
						background="linear-gradient(0deg,rgba(72, 124, 207, 0) 0%,--color-brandSecondary 100%),url(https://images.unsplash.com/photo-1564341505027-b410c159e1b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 50% 15%/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="48%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-brandCta"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							MI A SIKER IGAZI OKA?
						</Text>
						<Text as="p" margin="16px 0" font="--lead" max-width="400px">
							Egy több mint 40 éve tartó Harvard kutatás alapján beigazolódott, hogy a rendkívül sikeres emberek nem csak az örömöt képesek késleltetni, hanem kiemelkedően teremtenek kapcsolatot, barátkozó természetűek és könnyen ébresztenek bizalmat másokban. Azaz erős kapcsolatteremtő készséggel, emberismereti érzékkel és meggyőző képességgel rendelkeznek.
							<br />
							<br />
							<Strong>
								Mi pontosan ehhez adjuk a Mesterkulcsot a kezedbe.
							</Strong>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 120px 0" background="linear-gradient(180deg,rgba(255, 255, 255, 0.5) 71%,--color-brandSecondary 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/chess.jpg?v=2023-12-14T13:54:33.184Z) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-2">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text
				margin="0px 60% 80px 0px"
				font="normal 600 1.25em/1.5 --fontFamily-googleLexend"
				color="--brandPrimary"
				lg-margin="0px 40% 50px 0px"
				md-margin="0px 0 50px 0px"
			>
				Ha őszintén visszatekintünk a múltba, megtaláljuk a kudarcok és sikertelenségek közös okát: az alábbi három dolog szinte minden probléma esetén előfordul.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					background="--color-brandPrimary"
					padding="25px 20px 50px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					opacity="0.8"
					justify-items="center"
					grid-gap="25px"
					align-content="start"
				>
					<Icon
						category="fa"
						icon={FaUserTie}
						size="24px"
						color="--brandCta"
						min-width="44px"
						margin="0px 14px 0px 0px"
						lg-margin="0px 0 18px 0px"
						lg-justify-content="flex-start"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 20px 0px" color="--brandLight" font="--lead" text-align="left">
							1. ok, amiért elbukhatunk: (ÖN)MENEDZSMENT
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--brandLight">
							<Strong>
								“
								<Em>
									Más fog parancsolni annak, aki önmagának engedelmeskedni képtelen
								</Em>
								” - Nietzsche.
								<br />
								<br />
							</Strong>
							Nem vagyunk képesek a helyzethez megfelelően igazítani a viselkedésünket, ezért nem érjük el a várt sikert. Sőt, a legtöbb ember anélkül akar másokat vezetni, hogy előbb megtanulná önmagát irányítani. Az önmenedzsment az első ok, amiért elbukunk az életben.
							<br />
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					background="--color-brandPrimary"
					padding="25px 20px 50px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					opacity="0.8"
					justify-items="center"
					grid-gap="25px"
					align-content="start"
				>
					<Icon
						category="fa"
						icon={FaBinoculars}
						size="24px"
						color="--brandCta"
						min-width="44px"
						margin="0px 14px 0px 0px"
						lg-margin="0px 0 18px 0px"
						lg-justify-content="flex-start"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 20px 0px" color="--brandLight" font="--lead" text-align="left">
							2. ok, amiért kudarcot vallhatunk: MEGFIGYELÉS
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--brandLight">
							Nem tudjuk kellő pontossággal felmérni az adott szociális helyzetet és a másik ember motivációját, azaz hiányos vagy rossz a megfigyelésünk. Nem csak nézni, hanem látni kell a színfalak mögé.
							<br />
							<br />
							<Strong>
								Éppen ezért mélyebb emberismeretre van szükség, hogy képesek legyünk meglátni a valódi okokat a döntések mögött.
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					background="--color-brandPrimary"
					padding="25px 20px 50px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					opacity="0.8"
					justify-items="center"
					grid-gap="25px"
					align-content="start"
				>
					<Icon
						category="fa"
						icon={FaPeopleCarry}
						size="24px"
						color="--brandCta"
						min-width="44px"
						margin="0px 14px 0px 0px"
						lg-margin="0px 0 18px 0px"
						lg-justify-content="flex-start"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 20px 0px" color="--brandLight" font="--lead">
							3. ok, amiért nem sikerülhet: MEGGYŐZÉS
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--brandLight">
							Nem a beszélgetőpartnerünk karakterére van szabva a kommunikációnk. Ezért nem tudunk elegendő befolyást gyakorolni ahhoz, hogy kedvező eredményt érjünk el. Az ember olyan, mint egy zongora: csak éppen nem ugyanazok a billentyűk vannak mindenkinél, és teljesen másképp szólnak leütéskor a hangok. Emeld művészi szintre a kommunikációd azáltal, hogy szinkronba hozod a „hangokat” közted és a másik között:{" "}
							<Strong>
								szólj azon a nyelven, melyet a legjobban ért.
							</Strong>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
			background="linear-gradient(180deg,--color-brandSecondary 0%,rgba(72, 124, 207, 0.7) 51.4%,--color-brandSecondary 100%) 0 0 no-repeat,url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/handsv2.png?v=2023-12-06T22:10:27.614Z) 0% 0%/cover"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="block"
				width="100%"
				flex-direction="column"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				grid-template-columns="repeat(2, 1fr)"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						as="h1"
						margin="0px 0px 24px 0px"
						font="--headline2"
						color="--brandLight"
						width="100%"
						md-width="100%"
						md-margin="0px 0px 32px 0px"
						md-padding="0px 0px 0px 0px"
						text-align="left"
						md-text-align="center"
						sm-text-align="left"
					>
						Hitvallásunk
					</Text>
					<Text
						as="h1"
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--brandLight"
						width="50%"
						md-width="100%"
						md-margin="0px 0px 32px 0px"
						md-padding="0px 0px 0px 0px"
						text-align="left"
						md-text-align="center"
						lg-width="100%"
						sm-text-align="left"
					>
						<Strong>
							Szárnyalni, csak másokat emelve lehet!{" "}
						</Strong>
						Ezért kérünk, hogy megfelelő gondossággal használd az általunk oktatott technikákat, és segíts embertársaidnak jobb emberré válni!
					</Text>
				</Box>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					flex-direction="column"
					sm-align-items="flex-start"
					padding="25px 25px 25px 25px"
					background="--color-brandLight"
					border-radius="25px"
					opacity="0.8"
					display="grid"
					align-content="start"
				>
					<Text font="normal 500 1.5em/1.5 --fontFamily-googleLexend" color="--brandPrimary" margin="0px 0px 6px 0px" text-align="center">
						Miért kelünk fel reggel?
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandPrimary" sm-text-align="left">
						Célunk, hogy olyan örökséget hagyjunk hátra, amely jelentős és pozitív hatást gyakorol az életedre. Azért, hogy átlagon felüli eredményeket érj el az élet bármely területén, a legmodernebb tudást kutatjuk fel számodra a világ legelismertebb szakértőitől a profilozás, hírszerzés, befolyásolás, neurobiológia, és pszichológia területén.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Olyan szuperképességekkel vértezünk fel, melyek segítségével nem csak sikeresebb leszel, hanem boldogabb és fejlettebb szinten fogsz élni.
						</Strong>
					</Text>
				</Box>
				<Box
					flex-direction="column"
					sm-align-items="flex-start"
					padding="25px 25px 25px 25px"
					background="--color-brandLight"
					border-radius="25px"
					opacity="0.8"
					display="grid"
					align-content="start"
				>
					<Text font="normal 500 1.5em/1.5 --fontFamily-googleLexend" color="--brandPrimary" margin="0px 0px 6px 0px" text-align="center">
						Hogyan csináljuk?
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandPrimary" sm-text-align="left">
						Oktatási Platformunk (az Akadémia) ad otthont tananyagainknak, ahol mindent megtalálsz egy helyen. A kommunikáció, pszichológia, profilozás és befolyásolás világába vezetünk be különböző programok segítségével. Képzéseink mobilalkalmazáson keresztül is elérhetőek, ahol a tanulás és a közösségépítés összeolvad.
						<br />
						<br />
						Így tesszük lehetővé, hogy az online térben egyszerre kapcsolódj és fejlődj a hozzád hasonló emberekkel - reklámok és trollok nélkül.
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}Úgy képzeld el, mintha a Facebookot egy fejlett Online Oktatási Rendszerrel kombinálnád.
						</Strong>
					</Text>
				</Box>
				<Box
					flex-direction="column"
					sm-align-items="flex-start"
					padding="25px 25px 25px 25px"
					background="--color-brandLight"
					border-radius="25px"
					opacity="0.8"
					display="grid"
					align-content="start"
				>
					<Text font="normal 500 1.5em/1.5 --fontFamily-googleLexend" color="--brandPrimary" margin="0px 0px 6px 0px" text-align="center">
						Mit csinálunk?
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandPrimary" sm-text-align="left">
						Fejlesztünk, oktatunk, tanácsot adunk.{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Olyan tudásanyagot hozunk el számodra, amely civileknek idáig nem volt elérhető.
						</Strong>
						{" "}Célunk, hogy képzéseink segítségével tökéletesítsd a kommunikációdat, és tűpontosan értelmezd a világot magad körül.
						<br />
						<br />
						Ez a tudás nem csak elméleti; gyakorlati tapasztalatokon és valós helyzeteken alapul. Évtizedek óta használják és tesztelik különböző területeken, a különleges haderőktől kezdve a diplomáciai tárgyalásokig.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(180deg,--color-brandSecondary 0%,rgba(0,0,0,0) 100%) 0 0 no-repeat,url(https://uploads.quarkly.io/655c9b91867c86001f18116f/images/brainusb.jpg?v=2023-12-14T13:38:45.779Z) center/cover no-repeat"
			quarkly-title="Next step"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				sm-align-items="center"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
			/>
			<Box
				padding="50px 50px 80px 50px"
				color="--dark"
				opacity="0.8"
				border-radius="25px"
				background="--color-brandLight"
			>
				<Text as="h2" font="--headline2" md-font="--headline2" color="--brandCta">
					Lehetőségeid
				</Text>
				<Text as="p" font="--base" text-align="left" color="--brandPrimary">
					Most már csak{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						egy lépés
					</Strong>
					{" "}választ el attól, hogy elindulj a Szuperképességek felé vezető úton.
					<br />
					<br />
					{"  "}Válogass a{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						díjmentes oktatási anyagokból
					</Strong>
					, például végezd el a{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Befolyásolás és Profilozás Alapkurzust
					</Strong>
					,{" "}
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						töltsd le
					</Span>
					{" "}az FBI által is használt{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Viselkedés Periódusos Rendszert
					</Strong>
					, vagy nyerj részletes betekintést egy{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						tanfolyam tartalmába
					</Strong>
					, mielőtt beruházol a fejlődésedbe.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="center"
				align-items="center"
			>
				<Link
					color="--brandLight"
					text-align="center"
					background="--color-brandCta"
					hover-transform="translateY(-4px)"
					href="../szolgaltatasok"
					text-decoration-line="initial"
					padding="12px 24px 12px 24px"
					font="--lead"
					letter-spacing="0.5px"
					className="ctabutton"
					margin="25px 25px 25px 25px"
					border-radius="10px"
					border-style="solid"
					border-width="1px"
					hover-border-color="rgba(72, 124, 207, 0)"
					border-color="--color-brandLight"
					transition="all 0.5s ease 0s"
				>
					Megnézem A Szolgáltatásokat
				</Link>
			</Box>
		</Section>
		<Section
			sm-padding="24px 0 24px 0"
			box-sizing="border-box"
			justify-content="center"
			padding="90px 0px 100px 0px"
			border-color="--color-lightD2"
			lg-padding="50px 30px 50px 30px"
			quarkly-title="FAQ"
			background="--color-brandLight"
			overflow-x="hidden"
			overflow-y="hidden"
			display="none"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 48px 0px 48px"
				md-margin="0px 16px 0px 16px"
				align-items="center"
				align-content="space-around"
			/>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				lg-text-align="center"
			>
				gyakran ismételt kérdések
			</Text>
			<Text
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 36px 0px"
			>
				GY.I.K.
			</Text>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				text-align="center"
			>
				<Box margin="0px auto 0px auto">
					<Text
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
					>
						Hogyan tudom elérni az oktatási platformot asztali számítógépről és laptopról?
					</Text>
					<Text
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						Tetszőleges böngésződből bármikor elérheted a Sikeres Kommunikáció Akadiémát az{" "}
						<Link
							href="https://edu.sziklaybence.com"
							color="--brandSecondary"
							hover-color="--brandCta"
							text-decoration-line="initial"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							edu.sziklaybence.com
						</Link>
						{" "}webcímen.
					</Text>
				</Box>
				<Box margin="0px auto 0px auto">
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
					>
						Hol tudom letölteni a mobilalkalmazást?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						A MightyNetworks platformot használjuk, így az alkalmazás neve is MightyNetworks. Ezt a Google Play Áruházban vagy az Apple App Store-ban töltheted le, hogy hozzáférj minden szolgáltatásunkhoz a mobilodon.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						text-align="center"
						opacity="0.6"
						margin="25px 0px 0px 0px"
					>
						<LinkBox display="inline-block" padding="5px 5px 5px 5px" href="https://play.google.com/store/apps/details?id=com.mightybell.mb" target="_blank">
							<Icon
								category="fa"
								icon={FaGooglePlay}
								size="64px"
								margin="0px auto 0px auto"
								padding="5px 5px 5px 5px"
								width="30px"
								height="30px"
							/>
							<Text margin="0px 0px 0px 0px" font="16px --fontFamily-googleRoboto">
								Goolge Play
							</Text>
						</LinkBox>
						<LinkBox
							display="inline-block"
							text-align="center"
							padding="5px 5px 5px 5px"
							target="_blank"
							href="https://itunes.apple.com/us/app/id1081683081"
						>
							<Icon
								category="fa"
								icon={FaApple}
								size="64px"
								margin="0px auto 0px auto"
								padding="5px 5px 7px 5px"
								width="30px"
								height="30px"
							/>
							<Text margin="0px 0px 0px 0px" font="16px --fontFamily-googleRoboto">
								App Store
							</Text>
						</LinkBox>
					</Box>
				</Box>
				<Box margin="0px auto 0px auto">
					<Text
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						Hogyan zajlik az oktatás?
					</Text>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
					>
						A kurzusok előre felvett videóleckékből állnak, melyek kiegészülnek élő Kérdezz és Felelek, valamint Masterclass órákkal. A célunk az, hogy a lehető legjobban támogassunk a tanulásban, és mindig rendelkezésedre álljanak a legfrissebb információk, kiegészítő programok és segédanyagok.
					</Text>
				</Box>
				<Box margin="0px auto 0px auto">
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Van pénzvisszafizetési garancia?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Minden tanfolyamunkra 100% elégedettségi garanciát vállalunk. A vásárlást követő két hétig (14 napig), amennyiben nem vagy elégedett, kérdés nélkül visszatérítjük a befektetésed árát. Nincs kérdés, nincs harag.
					</Text>
				</Box>
				<Box margin="0px auto 0px auto">
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Technikai feltételek?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						A képzéseken való részvételhez csak alapvető technikai feltételek szükségesek: egy stabil internetkapcsolat és egy laptop, tablet vagy okostelefon.
					</Text>
				</Box>
				<Box margin="0px auto 0px auto">
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Hol tudok vásárolni?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Amint kiválasztottad a számodra megfelelő szolgáltatást, egyszerűen vásárold meg webshopunkban. Kurzusok esetén, a vásárlást követő 24 órán belül küldünk Neked e-mailben egy meghívót, amellyel hozzáférhetsz a tanfolyamhoz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" overflow-x="hidden" overflow-y="hidden" background="--color-brandLight">
			<Override slot="SectionContent" flex-direction="column" flex="0 1 auto" filter="--grayscale" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark" text-align="center">
					Médiamegjelenések
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
					width="60%"
				>
					Ahogyan a médiában láthattad
				</Text>
			</Box>
			<Box
				margin="40px 0px 0px 0px"
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="center"
			>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/podpad.svg?v=2023-11-23T15:44:09.737Z"
						border-radius="16px"
						max-width="150px"
						alt="PodPad logo"
						title="PodPad"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/logo.svg?v=2023-11-23T15:38:30.458Z"
						border-radius="16px"
						max-width="150px"
						alt="Metropol logo"
						title="Metropol"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15:36:10.609Z"
						border-radius="16px"
						max-width="150px"
						alt="Radio1 logo"
						title="Radio1"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/radio1.jpg?v=2023-11-23T15%3A36%3A10.609Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15:41:09.480Z"
						border-radius="16px"
						max-width="150px"
						background="#62a9cb"
						alt="Balázsék a showarcok logó"
						title="Balázsék a showarcok, Radio1"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/balazsek_2021_logo.png?v=2023-11-23T15%3A41%3A09.480Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15:37:17.846Z"
						border-radius="16px"
						max-width="150px"
						alt="Magyar Nemzet logo"
						title="Magyar Nemzet"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/Magyar_Nemzet_logo.png?v=2023-11-23T15%3A37%3A17.846Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15:34:24.737Z"
						border-radius="16px"
						max-width="150px"
						alt="TV2 logó"
						title="TV2"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/tv2.png?v=2023-11-23T15%3A34%3A24.737Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/indexhu.svg?v=2023-11-23T15:29:09.172Z"
						border-radius="16px"
						max-width="150px"
						alt="Index logó"
						title="Index.hu"
					/>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					sm-width="calc(100% / 2)"
					sm-margin="5px 0px 5px 0px"
					width="calc(100% / 6)"
					md-width="calc(100% / 3)"
				>
					<Image
						src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20:18:48.700Z"
						border-radius="16px"
						max-width="150px"
						alt="RTL logó"
						title="RTL"
						srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/rtl_transp_bg.png?v=2023-12-08T20%3A18%3A48.700Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.FooterGlobal>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="CreatorSignature" margin="60px auto 5px auto" />
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});